import { useEffect } from "react"
import { navigate } from "gatsby"

export default () => {
  useEffect(() => {
    navigate("/")
  }, [])
  return null
}

// import React from "react"
// import { graphql } from "gatsby"

// import Layout from "../components/layout"
// import SEO from "../components/seo"

// import Header from "../components/header"
// import SystemLink from "../components/system-link"

// const IndexPage = ({
//   data: {
//     allMarkdownRemark: { edges },
//   },
// }) => {
//   const Systems = edges
//     .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
//     .map(edge => <SystemLink key={edge.node.id} system={edge.node} />)

//   return (
//     <Layout>
//       <SEO title="Home" />
//       <Header siteTitle="Boka demo nu" />
//       <div style={{ maxWidth: "960px", margin: "0 auto" }}>
//         <h1>Alla systemen</h1>
//         <div>Alla system hos oss: {Systems}</div>
//       </div>
//     </Layout>
//   )
// }

// export default IndexPage

// export const pageQuery = graphql`
//   query {
//     allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
//       edges {
//         node {
//           id
//           excerpt(pruneLength: 250)
//           frontmatter {
//             date(formatString: "MMMM DD, YYYY")
//             path
//             title
//           }
//         }
//       }
//     }
//   }
// `
